.modal-intro .btn {
  text-align: center;
  z-index: 5;
  background-color: var(--primary-color);
  opacity: 0.9;
  margin-top: 2rem;
  transition: transform 200ms ease-in-out;
  padding: 1rem;
  display: block;
  letter-spacing: 1px;
}

.modal-intro:hover .btn {
  background-color: var(--secondary-color);
  color: #fff;
}
