.video-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
}

/* .video-container::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-image: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 75%,
    rgba(0, 0, 0, 0.3) 100%
  );

} */

.video-js {
  width: 100%;
  height: 100%;
  /* background-color: #e3e2dd; */
}

.video-js video.vjs-tech {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) scale(1);
  /* See: https://stackoverflow.com/questions/10797632/simulate-background-sizecover-on-video-or-img */
  object-fit: cover;
}

.custom-controls {
  position: absolute;
  z-index: 21;
  text-align: right;
  line-height: 1.5;
  bottom: 1rem;
  right: 1rem;
  transition: opacity 200ms ease-in-out;
  cursor: default;
  user-select: none;
}

.modal-open .custom-controls {
  opacity: 0;
  z-index: 19;
}

@media screen and (min-width: 640px) {
  .custom-controls {
    top: 1rem;
    right: 1rem;
    bottom: auto;
  }
}

@media screen and (min-width: 640px) and (min-height: 670px) {
  .custom-controls {
    top: 1.3rem;
    right: 2rem;
  }
}

@media screen and (min-width: 1536px) {
  .modal-open .custom-controls {
    opacity: 1;
  }
}

.custom-controls button {
  cursor: pointer;
  font-family: "PowerGrotesk";
  font-size: 1.5rem;
  display: block;
  margin-left: auto;
}

.custom-controls button:hover {
  color: var(--primary-color);
}

@media screen and (min-width: 640px) and (min-height: 670px) {
  .custom-controls button {
    font-size: 2rem;
  }
}

.remaining-time {
  display: inline-block;
  width: 7rem;
}

.custom-caption {
  background-color: #000;
  z-index: 11;
  position: absolute;
  bottom: 7rem;
  font-size: 3rem;
  font-family: "PowerGrotesk";
  padding: 0.5rem;
  left: 27%;
}

.video-progress {
  position: absolute;
  height: 10px;
  bottom: 0;
  left: 0;
  right: 0;
  margin-top: 1rem;
}

progress {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  height: 10px;
  pointer-events: none;
  position: absolute;
  background-color: transparent;
  border: none;
}

progress::-webkit-progress-bar {
  background-color: transparent;
}

progress::-webkit-progress-value {
  background: #fff;
}

progress::-moz-progress-bar {
  background-color: #fff;
}

.seek {
  position: absolute;
  bottom: 0;
  width: 100%;
  cursor: pointer;
  margin: 0;
  z-index: 16;
}

.seek:hover + .seek-tooltip {
  display: block;
}

.seek-tooltip {
  font-family: "PowerGrotesk";
  display: none;
  position: absolute;
  bottom: 2rem;
  margin-left: -40px;
  font-size: 2rem;
  color: #fff;
  z-index: 25;
  background-color: #000;
  padding: 5px;
  cursor: default;
  user-select: none;
}

input[type="range"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 20px;
  background: transparent;
  cursor: pointer;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  cursor: pointer;
  border-radius: 0;
  -webkit-appearance: none;
  transition: all 0.4s ease;
}

input[type="range"]::-webkit-slider-thumb {
  margin-bottom: -14px;
  height: 15px;
  width: 10px;
  border-radius: 0;
  background: var(--primary-color);
  cursor: pointer;
  -webkit-appearance: none;
  border: 0;
}

input[type="range"]::-moz-range-thumb {
  background: var(--primary-color);
  cursor: pointer;
  -moz-appearance: none;
  border: 0;
  border-radius: 0;
  height: 20px;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: transparent;
}

/* .intro .custom-controls,  */
.intro .video-progress {
  opacity: 0;
}

.vjs-text-track-display {
  bottom: 8rem !important;
}

@media screen and (min-width: 640px) {
  .vjs-text-track-display {
    bottom: 4rem !important;
  }
}

.vjs-text-track-display div {
  font-family: var(--font-mono) !important;
  color: var(--primary-color) !important;
}

.vjs-text-track-display div div div {
  padding: 1px 4px;
  box-decoration-break: clone;
}

.vjs-text-track-cue {
  opacity: 0.9;
  /* scale: 1.1; */
}

.green {
  color: var(--primary-color-muted);
}

.video-overlay-label {
  z-index: 19;
  position: absolute;
  top: 1rem;
  left: 3rem;
  font-size: 1.13rem;
  font-family: "PowerGrotesk";
  color: #000;
  opacity: 0.9;
  transition: opacity 200ms ease-in-out;
}

@media screen and (min-width: 640px) and (min-height: 670px) {
  .video-overlay-label {
    top: 2rem;
    left: 4rem;
    font-size: 1.5rem;
  }
}

.video-overlay-label span {
  position: relative;
  background-color: #fff;
  padding: 1px 5px;
  box-decoration-break: clone;
  font-weight: bold;
}

.video-overlay-label .line-2 span {
  font-weight: normal;
}

.video-overlay-label .line-2 {
  margin-top: 6px;
}

.logo-visible .video-overlay-label {
  opacity: 0;
}

.vjs-paused video {
  filter: url(../filter.svg#custom-filter);
}

/* Safari only */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .vjs-paused video {
      filter: grayscale(100%);
    }
  }
}

.vjs-poster {
  background-size: cover;
}
