.video-overlay-comment {
  position: absolute;
  right: 2rem;
  bottom: 2rem;
  max-width: 30rem;
  letter-spacing: 0.2px;
}

.message-queue {
  position: absolute;
  bottom: 12rem;
  left: 0;
  right: 0;
  font-size: 0.9rem;
  font-family: var(--font-mono);
  color: #000;
  display: block;
  width: 100%;
  line-height: 1.2;
}

.message-queue .line-1 {
  font-weight: bold;
  margin-right: 10px;
}

.video-message {
  background-color: rgb(255, 255, 255, 0.8);
  padding: 4px 8px;
  opacity: 0.9;
  transition: opacity 200ms ease-in-out;
  margin-top: 1px;
  border-radius: 3px;
  margin-right: 1rem;
  margin-left: 1rem;
}

@media screen and (min-width: 640px) {
  .message-queue {
    font-size: 1rem;
    bottom: 2rem;
    max-width: 26rem;
    left: auto;
    right: 2rem;
  }
  .video-message {
    margin-top: 2px;
    padding: 5px 6px;
    margin-right: 0;
    margin-left: 0;
  }
}

.comment-line {
  display: inline;
}
