.logo {
  font-family: "PowerGrotesk-Variable";
  font-weight: normal;
  font-variation-settings: "wght" 670, "ital" 0;
  letter-spacing: 0.1rem;
  font-size: 4rem;
  color: #fff;
  margin: 0;
  text-transform: uppercase;
  max-width: 15rem;
  word-wrap: break-word;
  line-height: 0.8;
  cursor: default;
  user-select: none;
  transition: opacity 200ms ease-in-out;
}

@media screen and (min-width: 640px) and (min-height: 670px) {
  .logo {
    font-size: 5rem;
    max-width: 18rem;
  }
}

.logo span {
  transition: opacity 200ms ease-in-out;
}

.info-container.open .logo {
  color: #000;
}

.logo.glitch::before,
.logo.glitch::after {
  top: 1rem;
  left: 1rem;
  opacity: 0.8;
  max-width: 15rem;
}

.glitch::before,
.glitch::after {
  position: absolute;
  content: attr(data-glitch);
  visibility: hidden;
}

@media screen and (min-width: 640px) and (min-height: 670px) {
  .logo.glitch::before,
  .logo.glitch::after {
    top: 2rem;
    left: 2rem;
  }
}

@media screen and (min-width: 640px) and (min-height: 670px) {
  .logo.glitch::before,
  .logo.glitch::after {
    max-width: 18rem;
  }
}

.glitch::after {
  color: var(--secondary-color);
  z-index: -2;
}

.glitch::before {
  color: var(--primary-color);
  z-index: -1;
}

.intro .logo-container:hover .glitch::before,
.glitch.animate::before {
  visibility: visible;
  animation: glitch 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both 5;
  animation-delay: 200ms;
}
.intro .logo-container:hover .glitch::after,
.glitch.animate::after {
  visibility: visible;
  animation: glitch 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) reverse both 5;
  animation-delay: 200ms;
}

@keyframes glitch {
  0% {
    transform: translate(0);
    font-variation-settings: "wght" 670, "ital" 0;
    animation-delay: 200ms;
  }
  20% {
    transform: translate(-2px, 2px);
    font-variation-settings: "wght" 670, "ital" 0.3;
  }
  40% {
    transform: translate(-2px, -2px);
    font-variation-settings: "wght" 670, "ital" 0;
  }
  60% {
    transform: translate(2px, 2px);
    font-variation-settings: "wght" 670, "ital" 0.2;
  }
  80% {
    transform: translate(2px, -2px);
    font-variation-settings: "wght" 670, "ital" 0.4;
  }
  to {
    transform: translate(0);
    font-variation-settings: "wght" 670, "ital" 0;
  }
}
