.menu {
  font-family: "PowerGrotesk";
  position: absolute;
  z-index: 25;
  left: 1rem;
  bottom: 1rem;
  color: #fff;
  font-size: 2rem;
}

@media screen and (min-width: 640px) and (min-height: 670px) {
  .menu {
    left: 2rem;
    bottom: 2rem;
  }
}

.menu a + a {
  margin-left: 1rem;
}

.page .menu-items,
.menu-items {
  display: flex;
  gap: 1rem;
}

.btn-menu-item {
  text-align: center;
  z-index: 5;
  border: 3px solid var(--primary-color);
  opacity: 0.9;
  margin-top: 1rem;
  transition: transform 200ms ease-in-out;
  padding: 0.9rem;
  letter-spacing: 1px;
  width: 50%;
}

@media screen and (min-width: 640px) and (min-height: 670px) {
  .menu-items {
    display: block;
  }
  .btn-menu-item {
    display: block;
    width: auto;
  }
}

.btn-menu-item:hover {
  background-color: var(--primary-color);
}
