.video-overlay-link {
  position: absolute;
  top: 50%;
  right: 1rem;
  z-index: 19;
  opacity: 0.9;
  transition: opacity 200ms ease-in-out, max-width 400ms ease-in-out;
  max-width: 2rem;
  height: 2rem;
  overflow: hidden;
}

.video-overlay-link:hover {
  max-width: 100%;
}

@media screen and (min-width: 640px) and (min-height: 670px) {
  .video-overlay-link {
    top: 50%;
    right: 2rem;
  }
}

.video-overlay-link .info-icon {
  height: 2rem;
  width: 2rem;
  fill: var(--primary-color);
  display: table-cell;
}

.video-overlay-link .caption {
  font-family: "PowerGrotesk";
  font-size: 1.5rem;
  vertical-align: middle;
  padding: 2px 10px;
  overflow: hidden;
  display: table-cell;
  /* height: 2rem; */
}

@media screen and (min-width: 640px) and (min-height: 670px) {
  .video-overlay-link .caption {
    font-size: 2rem;
  }
}
.video-overlay-link a {
  display: table;
}
