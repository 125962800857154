.video-container.page {
  position: absolute;
}

.page .modal-container {
  width: 100%;
  left: 0;
  position: relative;
}

.page .content {
  overflow-y: hidden;
}

.page .info-container {
  position: relative;
  z-index: 30;
}

@media screen and (min-width: 768px) and (min-height: 670px) {
  .video-container.page.episodes,
  .video-container.page.interviews {
    position: fixed;
  }
  .page.interviews .modal-container,
  .page.episodes .modal-container {
    position: absolute;
  }

  .page .content {
    overflow-y: scroll;
  }

  .page .info-container {
    position: absolute;
  }
}

.page .modal-container .content {
  max-width: 90rem;
  margin: 0 auto;
}

.page .menu {
  display: none;
}

@media screen and (min-width: 768px) and (min-height: 670px) {
  .page .videos-list {
    margin-top: 22rem;
  }
}

@media screen and (min-width: 1536px) {
  .page .videos-list {
    margin-top: 8rem;
  }
}

.page.info .logo,
.page.imprint .logo {
  color: #000;
}

.page.imprint .modal-container,
.page.info .modal-container {
  width: auto;
  overflow: hidden;
}

@media screen and (min-width: 768px) and (min-height: 670px) {
  .static-page-content,
  .page.info .info-content {
    margin-top: 26rem;
  }
}

.page.info .menu-items {
  max-width: 20rem;
  margin: 0 auto;
}

.page .btn-back {
  padding: 1rem;
}

@media screen and (min-width: 640px) and (min-height: 670px) {
  .page .btn-back {
    padding: 2rem;
  }
}

.page.interviews,
.page.episodes {
  background-color: #000;
}

.imprint.page .content,
.info.page .content {
  overflow: hidden;
}
