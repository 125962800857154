.video-overlay {
  cursor: default;
  user-select: none;
}

.video-overlay.hidden {
  opacity: 0;
}

.video-overlay-title {
  position: absolute;
  font-family: "PowerGrotesk";
  opacity: 0.9;
  font-size: 2rem;
  font-weight: bold;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translateX(-50%) translateY(-50%);
  transition: all 200ms ease-in-out;
  text-align: center;
  z-index: 19;
  max-width: 60rem;
}

@media screen and (min-width: 640px) and (min-height: 670px) {
  .video-overlay-title.lines-2 .line-2 span {
    font-size: 3rem;
  }
  .video-overlay-title.lines-1 {
    font-size: 3rem;
  }
}

.video-overlay-title span {
  position: relative;
  background-color: #000;
  padding: 5px 8px;
  line-height: 1.3;
  box-decoration-break: clone;
}

.video-overlay-title.lines-2 .line-1 span {
  background-color: var(--primary-color);
}

.video-overlay-title .line-2 span {
  background-color: #000;
}

.video-overlay-title.lines-1 span {
  background-color: #000;
}

.video-overlay-title div + div {
  margin-top: 1rem;
  font-weight: bold;
  font-size: 2rem;
  display: block;
}
