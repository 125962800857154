.modal-videos {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 25;
  background-color: #000;
  opacity: 0.99;
  color: #fff;
  padding: 0;
  overflow: hidden;
  display: flex;
}

.modal-videos::after {
  position: fixed;
  height: 8rem;
  bottom: 0;
  left: 0;
  right: 1rem;
  /* width: 100%; */
  content: "";
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 50%
  );
}

@media screen and (min-width: 640px) and (min-height: 670px) {
  .modal-videos::after {
    height: 10rem;
  }
}

@media screen and (min-width: 1536px) {
  .modal-videos {
    width: 50%;
    right: 0;
  }
  .modal-videos::after {
    position: absolute;
    height: 8rem;
  }
}

.modal-videos .content {
  padding: 1rem 1rem 7rem 1rem;
  overflow-y: scroll;
  width: 100%;
}

@media screen and (min-width: 640px) and (min-height: 670px) {
  .modal-videos .content {
    padding: 2rem 2rem 8rem 2rem;
  }
}

.videos-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.videos-list .item + .item {
  margin-top: 4rem;
}

.videos-list img,
.image-soon {
  margin-bottom: 1rem;
}

.videos-list {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 768px) {
  .videos-list .item {
    flex-direction: row;
    display: flex;
  }
  .videos-list .item div {
    width: 50%;
    flex: 1;
  }
  .episode-info {
    margin-left: 1rem;
  }
  .videos-list img,
  .image-soon {
    margin-bottom: 0;
  }
  .episode-image {
    margin-right: 1rem;
  }
}

.videos-list h3,
.videos-list h4 {
  text-transform: none;
  font-size: 1.5rem;
  font-weight: normal;
  font-family: "PowerGroteskMed";
  letter-spacing: 1px;
  padding: 0;
  margin: 0;
  transition: all 200ms ease-in-out;
}

.videos-list span {
  margin: 1rem 0;
  display: block;
  color: var(--primary-color);
}

.videos-list .not-released .details {
  color: var(--secondary-color);
}

.image-soon {
  background-color: var(--primary-color);
  height: 24vh;
}

.modal-videos .btn-close {
  position: fixed;
  bottom: 1rem;
  margin-left: 1rem;
  z-index: 26;
}

@media screen and (min-width: 640px) and (min-height: 670px) {
  .modal-videos .btn-close {
    bottom: 2rem;
    margin-left: 2rem;
  }
}

a.item:hover img,
.not-released img {
  filter: url(../filter.svg#custom-filter);
}

/* Safari only */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    a.item:hover img,
    .not-released img {
      filter: grayscale(100%);
    }
  }
}

a.item:hover h3 {
  color: var(--primary-color);
}
