/* Webfont: PowerGrotesk-Regular */
@font-face {
  font-family: "PowerGrotesk";
  src: url("./fonts/PowerGrotesk-Regular.eot"); /* IE9 Compat Modes */
  src: url("./fonts/PowerGrotesk-Regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/PowerGrotesk-Regular.woff") format("woff"),
    /* Modern Browsers */ url("./fonts/PowerGrotesk-Regular.ttf")
      format("truetype"); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: PowerGrotesk-Italic */
@font-face {
  font-family: "PowerGrotesk";
  src: url("./fonts/PowerGrotesk-Italic.eot"); /* IE9 Compat Modes */
  src: url("./fonts/PowerGrotesk-Italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/PowerGrotesk-Italic.woff") format("woff"),
    /* Modern Browsers */ url("./fonts/PowerGrotesk-Italic.ttf")
      format("truetype"); /* Safari, Android, iOS */
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: PowerGrotesk-Medium */
@font-face {
  font-family: "PowerGroteskMed";
  src: url("./fonts/PowerGrotesk-Medium.eot"); /* IE9 Compat Modes */
  src: url("./fonts/PowerGrotesk-Medium.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/PowerGrotesk-Medium.woff") format("woff"),
    /* Modern Browsers */ url("./fonts/PowerGrotesk-Medium.ttf")
      format("truetype"); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: PowerGrotesk-Bold */
@font-face {
  font-family: "PowerGrotesk";
  src: url("./fonts/PowerGrotesk-Bold.eot"); /* IE9 Compat Modes */
  src: url("./fonts/PowerGrotesk-Bold.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/PowerGrotesk-Bold.woff") format("woff"),
    /* Modern Browsers */ url("./fonts/PowerGrotesk-Bold.ttf")
      format("truetype"); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}

/* Webfont: PowerGrotesk-BoldItalic */
@font-face {
  font-family: "PowerGrotesk";
  src: url("./fonts/PowerGrotesk-BoldItalic.eot"); /* IE9 Compat Modes */
  src: url("./fonts/PowerGrotesk-BoldItalic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/PowerGrotesk-BoldItalic.woff") format("woff"),
    /* Modern Browsers */ url("./fonts/PowerGrotesk-BoldItalic.ttf")
      format("truetype"); /* Safari, Android, iOS */
  font-style: italic;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}

/* Webfont: PowerGrotesk-Variable */
@font-face {
  font-family: "PowerGrotesk-Variable";
  src: url("./fonts/PowerGrotesk-Variable.woff2") format("woff2-variations"),
    url("./fonts/PowerGrotesk-Variable.ttf") format("truetype"); /* Safari, Android, iOS */
  text-rendering: optimizeLegibility;
}

/* ibm-plex-mono-regular - latin */
@font-face {
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/ibm-plex-mono-v12-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./fonts/ibm-plex-mono-v12-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/ibm-plex-mono-v12-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./fonts/ibm-plex-mono-v12-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("./fonts/ibm-plex-mono-v12-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./fonts/ibm-plex-mono-v12-latin-regular.svg#IBMPlexMono")
      format("svg"); /* Legacy iOS */
}
/* ibm-plex-mono-italic - latin */
@font-face {
  font-family: "IBM Plex Mono";
  font-style: italic;
  font-weight: 400;
  src: url("./fonts/ibm-plex-mono-v12-latin-italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./fonts/ibm-plex-mono-v12-latin-italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/ibm-plex-mono-v12-latin-italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./fonts/ibm-plex-mono-v12-latin-italic.woff") format("woff"),
    /* Modern Browsers */ url("./fonts/ibm-plex-mono-v12-latin-italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./fonts/ibm-plex-mono-v12-latin-italic.svg#IBMPlexMono")
      format("svg"); /* Legacy iOS */
}
/* ibm-plex-mono-700 - latin */
@font-face {
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/ibm-plex-mono-v12-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./fonts/ibm-plex-mono-v12-latin-700.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/ibm-plex-mono-v12-latin-700.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("./fonts/ibm-plex-mono-v12-latin-700.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/ibm-plex-mono-v12-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./fonts/ibm-plex-mono-v12-latin-700.svg#IBMPlexMono") format("svg"); /* Legacy iOS */
}
/* ibm-plex-mono-700italic - latin */
@font-face {
  font-family: "IBM Plex Mono";
  font-style: italic;
  font-weight: 700;
  src: url("./fonts/ibm-plex-mono-v12-latin-700italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./fonts/ibm-plex-mono-v12-latin-700italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/ibm-plex-mono-v12-latin-700italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./fonts/ibm-plex-mono-v12-latin-700italic.woff") format("woff"),
    /* Modern Browsers */ url("./fonts/ibm-plex-mono-v12-latin-700italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./fonts/ibm-plex-mono-v12-latin-700italic.svg#IBMPlexMono")
      format("svg"); /* Legacy iOS */
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --font-mono: "IBM Plex Mono", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-family: var(--font-mono);
  --primary-color: #2aeab1;
  --secondary-color: #ff4545;
  --primary-color-muted: #91e0c9;
  --secondary-color-muted: #fac5c5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.line-through {
  text-decoration: line-through;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

.hide {
  display: none !important;
}

.hidden {
  opacity: 0;
}

.hidden::after,
.hidden::before {
  opacity: 0;
}

p a {
  border-bottom: 2px solid var(--primary-color);
  transition: all 100ms;
}

p a:hover {
  border-bottom-color: var(--secondary-color);
}

a > img {
  border: none;
}

p a img {
  display: block;
}

.red {
  color: var(--secondary-color);
}

/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: var(--primary-color) transparent;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 15px;
}

*::-webkit-scrollbar-track {
  background: transparent;
  background-clip: content-box;
}

*::-webkit-scrollbar-track-piece {
  background-color: #2aeab1;
  box-shadow: inset 0px 0px 0px 6px #fff;
}

*::-webkit-scrollbar-thumb {
  background-image: linear-gradient(
    180deg,
    var(--primary-color) 0%,
    var(--secondary-color) 99%
  );
  border-radius: 3px;
}

.modal-videos *::-webkit-scrollbar-track-piece {
  -webkit-box-shadow: inset 0px 0px 0px 6px #000;
  box-shadow: inset 0px 0px 0px 6px #000;
}
