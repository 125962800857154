.logo-container {
  margin-right: 1rem;
  margin-left: 1rem;
  margin-top: 1rem;
}

@media screen and (min-width: 640px) and (min-height: 670px) {
  .logo-container {
    margin-right: 2rem;
    margin-left: 2rem;
    margin-top: 2rem;
  }
}

.btn {
  appearance: none;
  border: none;
  background: none;
  color: inherit;
  line-height: inherit;
  display: inline-block;
  cursor: pointer;
  font-family: "PowerGrotesk";
  font-size: 1.5rem;
  padding: 0;
  transition: color 200ms ease-in-out;
}

@media screen and (min-width: 640px) and (min-height: 670px) {
  .btn {
    font-size: 2rem;
  }
}

.info-container.open + .menu .btn-info {
  color: #000;
}

.btn-info:hover,
.btn-close:hover {
  color: var(--primary-color);
}
