.modal-container {
  padding: 2rem 1rem 7rem 1rem;
  position: relative;
}

.modal-container ul,
.modal-container li,
.modal-container p {
  font-size: 1.1rem;
  line-height: 1.4;
}

@media screen and (min-width: 640px) and (min-height: 670px) {
  .modal-container {
    padding: 2rem 2rem 8rem 2rem;
  }
  .modal-container ul,
  .modal-container li,
  .modal-container p {
    font-size: 1.2rem;
    line-height: 1.5;
  }
}

@media screen and (min-width: 768px) {
  .modal-container {
    overflow-y: scroll;
  }
}

.modal-container h2 {
  padding-top: 0;
  margin-top: 0;
  text-align: right;
  font-family: "PowerGrotesk";
}

.modal-container h2,
.modal-container h3 {
  font-family: "PowerGrotesk";
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
}

.modal-container h3 {
  margin-top: 3rem;
}

.evz-logo,
.democ-logo {
  height: auto;
  margin-top: 2rem;
}

img {
  max-width: 100%;
}

.info-container {
  position: absolute;
  z-index: 20;
  left: 0;
  top: 0;
  right: 0;
  display: flex;
  overflow-x: hidden;
  max-width: 17rem;
}

@media screen and (min-width: 640px) {
  .info-container {
    right: auto;
    /* overflow: hidden; */
    max-width: 22rem;
  }
}

@media screen and (min-width: 1536px) {
  .info-container.info-container.open {
    width: 50%;
  }
}

.info-container.open {
  background-color: #fff;
  opacity: 0.9;
  bottom: 0;
  flex-direction: column;
  max-width: 100%;
}

.info-container.open::after {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 1rem;
  height: 8rem;
  content: "";
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 50%
  );
}

@media screen and (min-width: 640px) and (min-height: 670px) {
  .info-container.open::after {
    height: 10rem;
  }
}

/* medium */
@media screen and (min-width: 768px) {
  .info-container.open {
    flex-direction: row;
  }

  .info-container.open::after {
    position: absolute;
    height: 4rem;
  }
}

.social-icons svg {
  width: 30px;
  height: 30px;
  margin-right: 0.5rem;
  padding: 0.4rem;
}

.social-icons svg:hover {
  fill: var(--secondary-color);
}
