.tagline {
  max-width: 15rem;
  font-weight: normal;
  transition: opacity 200ms ease-in-out;
  margin-top: 0.8rem;
  margin-bottom: 2rem;
  cursor: default;
  user-select: none;
  line-height: 1.2;
}

.tagline span {
  font-size: 1.2rem;
  color: #fff;
  padding: 0 0.25rem;
  line-height: 1.3;
  background-color: #000;
  display: none;
  box-decoration-break: clone;
}

@media screen and (min-height: 470px) {
  .tagline span {
    display: inline;
  }
}

@media screen and (min-width: 640px) and (min-height: 670px) {
  .tagline {
    max-width: 18rem;
    margin-top: 1.1rem;
  }
  .tagline span {
    line-height: 1.5;
    font-size: 1.5rem;
  }
}

.info-container.open .tagline span {
  color: #000;
  background-color: transparent;
}
